import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div>
        <section className="text-center">
          <div className="relative h-24 overflow-hidden">
            <img className="block w-1/2 mx-auto mb-8" src={`../../moonkoalas.png`} alt="moonkoalas" />
          </div>
        </section>
        <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Looks like this page is a ghost that got abducted by aliens...
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;